import React, { useEffect, useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { useKeycloak } from '@react-keycloak/web'
import config from '../../config/config.json'
import PlannedEvent from './PlannedEvent'

const RecurringEventsStyles = makeStyles((theme) => ({
  eventList: {
  },
  loadingBox: {
  },
  errorBox: {
  },
}))

const RecurringEvents = ({limit}) => {
  const classes = RecurringEventsStyles()
  const { keycloak } = useKeycloak()

  const [ status, setStatus ] = useState("loading")
  const [ events, setEvents ] = useState([])

  useEffect(() => {
    const query = "/bff/cms/items/calendar_internal" +
      "?filter[recurrence_description][_nempty]=true" +
      "&sort=order" +
      (limit > 0 ? "&limit=" + limit : "")
    fetch(encodeURI(config.bffUrl + query), {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + keycloak.token,
      },
    })
    .then((res) => {
      if (res.ok) return res.json()
      else throw Error(res.statusText)
    })
    .then((res) => {
      setStatus("done")
      setEvents(res.data)
    }, (error) => {
      setStatus("error")
    })
  }, [keycloak, limit])

  if (status === "loading") {
    return(
      <Box className={classes.loadingBox}>
        <PlannedEvent />
        <PlannedEvent />
      </Box>
    )
  } else if (status === "error") {
    return(
      <Box className={classes.errorBox}>
        Fehler beim laden der Termine :-(
      </Box>
    )
  } else if (status === "done") {
    return (
      <Box className={classes.eventList}>
        {events.map((event) => (
          <PlannedEvent key={event.uuid} event={event} />
        ))}
      </Box>
    )
  }
}

export default RecurringEvents
