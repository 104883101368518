import React from 'react'
import { Link as MuiLink } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

const Link = (props) => {
  return (
    <React.Fragment>
      <MuiLink color="inherit" component={RouterLink} {...props}>
        {props.children}
      </MuiLink>
    </React.Fragment>
  )
}

export default Link
