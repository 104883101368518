import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { CssBaseline, ThemeProvider, Box } from '@material-ui/core'

import KeycloakConfig from './config/KeycloakConfig'
import { ReactKeycloakProvider } from '@react-keycloak/web'

import InternalHome from './pages/InternalHome'
import News from './pages/News'
import Calendar from './pages/Calendar'
import Recordings from './pages/Recordings'

import Theme from './components/layout/Theme'
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
import Secured from './components/layout/Secured'

function App() {
  return (
    <ReactKeycloakProvider
      authClient={KeycloakConfig}
      initOptions={{
        onLoad: "login-required",
      }}
      >
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <BrowserRouter>
          <Box>
            <Header />
            <Box>
              <Switch>
                <Route exact path="/">
                  <Secured><InternalHome /></Secured>
                </Route>
                <Route path="/news">
                  <Secured><News /></Secured>
                </Route>
                <Route path="/termine">
                  <Secured><Calendar /></Secured>
                </Route>
                <Route path="/predigten">
                  <Secured><Recordings /></Secured>
                </Route>
              </Switch>
            </Box>
            <Footer />
          </Box>
        </BrowserRouter>
      </ThemeProvider>
    </ReactKeycloakProvider>
  )
}

export default App
