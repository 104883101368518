import { Box, Container, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import useTitle from '../components/layout/UseTitle'
import Highlight from '../components/common/Highlight'
import CmsContent from '../components/content/CmsContent'

const Styles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    "&>*": {
      flexBasis: "100%",
      margin: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  heading: {
    marginTop: "3rem",
  },
}))

const Recordings = () => {
  useTitle("Predigten")
  const classes = Styles()
  return (
    <React.Fragment>
      <Container maxWidth="md" className={classes.container}>

        <Box>
          <Typography variant="h2" className={classes.heading}>
            <Highlight>Predigtplan</Highlight>
          </Typography>
          <CmsContent contentKey="predigtplan" />
        </Box> 

        <Box>
          <Typography variant="h2" className={classes.heading}>
            <Highlight>Aufnahmen</Highlight>
          </Typography>
          <CmsContent contentKey="aufnahmen" />
        </Box>

      </Container>
    </React.Fragment>
  )
}

export default Recordings
