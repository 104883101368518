import { Box, Container, makeStyles } from '@material-ui/core'
import React from 'react'
import ShowForChurchMember from '../auth/ShowForChurchMember'
import CmsContent from '../content/CmsContent'

const FooterStyles = makeStyles((theme) => ({
  container: {
    marginTop: "2rem",
    paddingTop: "2rem",
    paddingBottom: "12rem",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.dark,
  },
}))

const Footer = () => {
  const classes = FooterStyles()
  return (
    <React.Fragment>
      <Box className={classes.container}>
        <Container maxWidth="md">
          <ShowForChurchMember>
            <CmsContent contentKey="footer" />
          </ShowForChurchMember>
        </Container>
      </Box>
    </React.Fragment>
  )
}

export default Footer

