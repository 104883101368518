import React from 'react'
import VideoIcon from '@material-ui/icons/Videocam'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';

const Styles = makeStyles((theme) => ({
  creds: {
    ...theme.typography.body1,
    display: "grid",
    gridTemplateColumns: 'auto auto',
    columnGap: '1rem',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'auto',
    },
  },
  credsTitle: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  credsValue: {
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  actions: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
}))

const ZoomLink = ({meetingUrl, meetingId, meetingPw}) => {
  const classes = Styles()

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const withSpaces = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
  }

  return (
    <React.Fragment>
      <Dialog open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth="true">
        <DialogContent>
          <Typography paragraph>
            Starte das Meeting direkt über <Link href={meetingUrl}
            target="_blank" rel="noopener noreferrer"
            style={{marginLeft: '0.2rem', marginRight: '0.2rem'}}>diesen Link
            </Link> oder verwende die folgenden Zugangsdaten:
          </Typography>
          <Box className={classes.creds}>
            <Box className={classes.credsTitle}>Meeting-ID:</Box>
            <Box className={classes.credsValue}>{withSpaces(meetingId)}</Box>
            <Box className={classes.credsTitle}>Meeting-Passwort:</Box>
            <Box className={classes.credsValue}>{withSpaces(meetingPw)}</Box>
          </Box>
          <DialogContentText>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={handleClose}>
            Schließen
          </Button>
          <Button variant="contained"
            color="secondary"
            component="a"
            href={meetingUrl}
            target="_blank"
            rel="noopener noreferrer">Zoom Meeting starten</Button>
        </DialogActions>
      </Dialog>
      <Chip
        icon={<VideoIcon fontSize="small" />}
        label="Zoom"
        onClick={handleClickOpen}
        color="secondary"/>
    </React.Fragment>
  )
}

export default ZoomLink
