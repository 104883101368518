import { Button, Link, Typography } from '@material-ui/core'
import HTMLReactParser, { domToReact } from 'html-react-parser'
import ZoomLink from './ZoomLink'

const options = {
  replace: ({name, attribs, children}) => {
    if (name === 'h1') {
      return (<Typography variant='h3'>{domToReact(children, options)}</Typography>)
    }
    if (name === 'h2') {
      return (<Typography variant='h4'>{domToReact(children, options)}</Typography>)
    }
    if (name === 'h3') {
      return (<Typography variant='h5'>{domToReact(children, options)}</Typography>)
    }
    if (name === 'p') {
      return (<Typography variant='body1' paragraph>{domToReact(children, options)}</Typography>)
    }
    if (name === 'li') {
      return (<li><Typography variant='body1' paragraph>{domToReact(children, options)}</Typography></li>)
    }
    if (name === 'a' && attribs['data-zoom-id']) {
      return (
        <ZoomLink meetingUrl={attribs['href']}
          meetingId={attribs['data-zoom-id']}
          meetingPw={attribs['data-zoom-pw']} />
      )
    }
    if (name === 'a' && attribs['data-element'] && attribs['data-element'] === 'button') {
      return (
        <Button href={attribs['href']}
          color={attribs['data-color'] ? attribs['data-color'] : "primary"}
          variant={attribs['data-variant'] ? attribs['data-variant'] : "contained"}
          target={attribs['target'] ? attribs['target'] : "_blank"}
          rel="noreferrer noopener nofollow">
          {domToReact(children, options)}
        </Button>
      )
    }
    if (name === 'a') {
      return (
        <Link href={attribs['href']}
          target={attribs['target'] ? attribs['target'] : "_blank"}
          rel="noreferrer noopener nofollow">
          {domToReact(children, options)}
        </Link>
      )
    }
  }
}

const HtmlContent = ({html}) => {
  return HTMLReactParser(html, options)
}

export default HtmlContent
