import { Container, makeStyles } from '@material-ui/core'
import React from 'react'
import useTitle from '../components/layout/UseTitle'
import NewsItems from '../components/news/NewsItems'

const NewsStyles = makeStyles((theme) => ({
  container: {
  },
}))

const News = () => {
  useTitle("News")
  const classes = NewsStyles()
  return (
    <React.Fragment>
      <Container maxWidth="sm" className={classes.container}>
        <NewsItems />
      </Container>
    </React.Fragment>
  )
}

export default News
