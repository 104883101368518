import { createMuiTheme, responsiveFontSizes } from "@material-ui/core"
import "@fontsource/yeseva-one/400.css"
import "@fontsource/abel/400.css"

const Theme = createMuiTheme({
  palette: {
    type: 'light',
    text: {
      primary: '#011627',
      light: '#808a93',
    },
    primary: {
      //main: '#577399',
      //main: '#008dd5',
      //main: '#24305e',
      //main: '#5da2d5',
      //main: '#314455',
      //main: '#123c69',
      main: '#08415C',
      //main: '#011627',
      //main: '#5398BE',
    },
    secondary: {
      //main: '#FE5F55',
      //main: '#f56476',
      //main: '#f76c6c',
      //main: '#f78888',
      //main: '#c96567', //'#9e5a63',
      //main: '#ac3b61',
      //main: '#cc2936',
      //main: '#E65F5C',
      main: '#F9C22E',
      //contrastText: '#ffffff',
    },
    background: {
      default: '#FDFFFC',
      dark: '#052C42',
    },
  },
  typography: {
    htmlFontSize: 10,
    fontSize: 12,
    fontFamily: '"Abel", sans-serif',
    fontWeight: 300,
    body1: {
    },
    h1: {
      fontFamily: '"Yeseva One", serif',
      fontSize: '4rem',
    },
    h2: {
      fontFamily: '"Yeseva One", serif',
      fontSize: '3rem',
    },
    h3: {
      fontFamily: '"Yeseva One", serif',
      fontSize: '2rem',
    },
    h4: {
      fontFamily: '"Yeseva One", serif',
      fontSize: '1.8rem',
    },
    h5: {
      fontFamily: '"Yeseva One", serif',
      fontSize: '1.5rem',
    },
  },
})

export default responsiveFontSizes(Theme)
