import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import HtmlContent from '../common/HtmlContent'
import Highlight from '../common/Highlight'

const NewsItemStyles = makeStyles((theme) => ({
  container: {
  },
  title: {
    textAlign: 'center',
    marginTop: '3rem',
    marginBottom: '1rem',
  },
  content: {
    marginBottom: '5rem',
  },
}))

const NewsItem = ({item}) => {
  const classes = NewsItemStyles()

  return (
    <React.Fragment>
      <Box className={classes.container}>
        <Typography variant="h2" className={classes.title}>
          <Highlight>{item.title}</Highlight>
        </Typography>
        <Box className={classes.content}>
          <HtmlContent html={item.content} />
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default NewsItem
