import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Plausible from 'plausible-tracker';


if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.log("skipping analytics initialization on a dev environment")
} else if (window.localStorage.getItem('do-not-track-the-moderators') === "true") {
  console.log("skipping analytics initialization because of do-not-track value in local storage")
} else {
  const plausible = Plausible({
    domain: 'intern.liesing.church',
    apiHost: 'https://plausible.leranda.com',
    trackLocalhost: false
  })
  plausible.enableAutoPageviews()
  plausible.enableAutoOutboundTracking()
}


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
