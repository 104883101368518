import { Box, Container, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import useTitle from '../components/layout/UseTitle'
import PlannedEvents from '../components/calendar/PlannedEvents'
import RecurringEvents from '../components/calendar/RecurringEvents'
import Highlight from '../components/common/Highlight'
import LinkButton from '../components/common/LinkButton'
import NewsItems from '../components/news/NewsItems'

const InternalHomeStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    }
  },
  title: {
    marginTop: '2rem',
    marginBottom: '0.5rem',
  },
  sidePane: {
    flexBasis: "35%",
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    "& h1": {
      marginTop: '1rem',
    }
  },
  mainPane: {
    flexBasis: "65%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
  },
  eventContainer: {
    width: '100%',
  },
  moreButton: {
    marginBlockEnd: '2rem',
  }
}))

const MoreButton = (props) => {
  const classes = InternalHomeStyle()
  return (
    <LinkButton {...props}
      className={classes.moreButton}
      variant="contained"
      color="primary"
      size="small">
      {props.children}
    </LinkButton>
  )
}

const InternalHome = () => {
  useTitle("Übersicht")
  const classes = InternalHomeStyle()
  return (
    <React.Fragment>
      <Container maxWidth="lg" className={classes.container}>
        <Box className={classes.sidePane}>
          <Typography variant="h2" className={classes.title}>
            <Highlight>Termine</Highlight>
          </Typography>
          <Box className={classes.eventContainer}><PlannedEvents limit={2} /></Box>
          <MoreButton to="/termine">Alle geplanten Termine </MoreButton>
          <Box className={classes.eventContainer}><RecurringEvents limit={10} /></Box>
          <MoreButton to="/termine">Alle regelmäßigen Termine </MoreButton>
        </Box>
        <Box className={classes.mainPane}>
          <NewsItems limit={1} />
          <MoreButton to="/news">Ältere Beiträge lesen</MoreButton>
        </Box>
      </Container>
    </React.Fragment>
  )
}

export default InternalHome
