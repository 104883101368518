import React from 'react'
import { Box, makeStyles, Paper, Typography } from '@material-ui/core'
import HtmlContent from '../common/HtmlContent'
import Skeleton from '../common/Skeleton'
import DateIcon from '@material-ui/icons/Event';
import LocationIcon from '@material-ui/icons/LocationOn';

const PlannedEventStyles = makeStyles((theme) => ({
  container: {
    margin: '1rem',
  },
  title: {
    padding: '1rem',
    paddingBottom: '0.5rem',
    borderBottom: '1px solid ' + theme.palette.primary.light,
  },
  detailContainer: {
    padding: '1rem',
    paddingBottom: '0.75rem',
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: '1.4rem',
    marginRight: '0.4rem',
    color: theme.palette.text.secondary,
  },
  description: {
    backgroundColor: theme.palette.background.default,
    padding: '1rem',
    '& .MuiTypography-body1': {
      fontSize: '1.1rem',
    }
  },
}))

const PlannedEvent = ({event}) => {
  const classes = PlannedEventStyles()

  const formatDate = (dateStr) => {
    const date = new Date(dateStr)
    return date.toLocaleDateString("de")
  }

  if (event) {
    const dateBox = event.recurrence_description ?
      <Box>{event.recurrence_description}</Box> :
      <Box>von {formatDate(event.start_date)} {event.start_time} bis {formatDate(event.end_date)} {event.end_time}</Box>

    return (
      <React.Fragment>
        <Paper className={classes.container} elevation={5}>

          <Typography className={classes.title} variant="h5">{event.title}</Typography>

          <Box className={classes.detailContainer}>
            <Box className={classes.detail}>
              <DateIcon className={classes.icon} />
              {dateBox}
            </Box>
            <Box className={classes.detail}>
              <LocationIcon className={classes.icon} />
              <Box>{event.location}</Box>
            </Box>
          </Box>

          {event.description && 
            <Box className={classes.description}>
              <HtmlContent html={event.description} />
            </Box>
          }

        </Paper>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <Paper className={classes.container} elevation={5}>

          <Typography className={classes.title} variant="h5">
            <Skeleton variant="rect" animation="wave" />
          </Typography>

          <Box className={classes.detailContainer}>
            <Box className={classes.detail}>
              <DateIcon className={classes.icon} />
              <Skeleton variant="rect" animation="wave" width={150} />
            </Box>
            <Box className={classes.detail}>
              <LocationIcon className={classes.icon} />
              <Skeleton variant="rect" animation="wave" width={180} />
            </Box>
          </Box>

          <Box className={classes.description}>
            <Skeleton variant="rect" animation="wave" />
          </Box>

        </Paper>
      </React.Fragment>
    )
  }
}

export default PlannedEvent
