import React from 'react';

const TITLE_TEMPLATE = "%s | Gemeinde Liesing"

const useTitle = (title) => {
  React.useEffect(() => {
    const prevTitle = document.title;
    document.title = `${title} | Gemeinde Liesing`;
    return () => {
      document.title = prevTitle;
    }
  }, []);
}

export default useTitle
