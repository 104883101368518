import { Skeleton as MuiSkeleton } from '@material-ui/lab'

const Skeleton = (props) => {
  return (
    <MuiSkeleton
      animation="wave"
      style={{ borderRadius: "2px", margin: "2px" }}
      {...props} />
  )
}

export default Skeleton
