import React from 'react'
import { Button } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

const LinkButton = (props) => {
  return (
    <React.Fragment>
      <Button color="inherit" component={RouterLink} {...props}>
        {props.children}
      </Button>
    </React.Fragment>
  )
}

export default LinkButton
