import React from "react"
import { useKeycloak } from '@react-keycloak/web'
import SigningInIndicator from '../auth/SigningInIndicator'
import UserNotActivatedInformation from '../auth/UserNotActivatedInformation'

const Secured = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const authenticated = initialized && keycloak.authenticated;
  const userIsChurchMember = authenticated && keycloak.hasRealmRole("church_member") && !keycloak.hasRealmRole("new");
  const userIsNew = authenticated && !userIsChurchMember;

  return (
    <React.Fragment>
      {!initialized && (
        <SigningInIndicator />
      )}
      {initialized && !authenticated && (
        // should not happen; the user should be redirected to keycloak in this case
        <span>Du bist nicht angemeldet.</span>
      )}
      {userIsNew && (
        <UserNotActivatedInformation />
      )}
      {userIsChurchMember && (
        props.children
      )}
    </React.Fragment>
  )
}

export default Secured
