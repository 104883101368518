import React, { useEffect, useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import NewsItem from './NewsItem'
import { useKeycloak } from '@react-keycloak/web'
import config from '../../config/config.json'

const NewsItemsStyles = makeStyles((theme) => ({
  eventList: {
  },
  loadingBox: {
  },
  errorBox: {
  },
}))

const NewsItems = ({limit}) => {
  const classes = NewsItemsStyles()
  const { keycloak } = useKeycloak()

  const [ status, setStatus ] = useState("loading")
  const [ items, setItems ] = useState([])

  useEffect(() => {
    const query = "/bff/cms/items/news_internal" +
      "?sort=-date_created" +
      (limit > 0 ? "&limit=" + limit : "")
    fetch(encodeURI(config.bffUrl + query), {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + keycloak.token,
      },
    })
    .then((res) => {
      if (res.ok) return res.json()
      else throw Error(res.statusText)
    })
    .then((res) => {
      setStatus("done")
      setItems(res.data)
    }, (error) => {
      setStatus("error")
    })
  }, [keycloak, limit])

  if (status === "loading") {
    return(
      <Box className={classes.loadingBox}>
        News werden geladen ...
      </Box>
    )
  } else if (status === "error") {
    return(
      <Box className={classes.errorBox}>
        Fehler beim laden der News :-(
      </Box>
    )
  } else if (status === "done") {
    return (
      <Box className={classes.eventList}>
        {items.map((item) => (
          <NewsItem key={item.uuid} item={item} />
        ))}
      </Box>
    )
  }
}

export default NewsItems
