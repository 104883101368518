import React, { useState } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { AppBar, Box, Drawer, Hidden, IconButton, List, ListItem, ListItemIcon, ListItemText, makeStyles, Tab, Tabs, Toolbar } from '@material-ui/core'

import Link from '../common/Link'
import ProfileButton from '../auth/ProfileButton'

import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/Home'
import NewsIcon from '@material-ui/icons/SpeakerNotes'
import CalendarIcon from '@material-ui/icons/Today'
import PreachingsIcon from '@material-ui/icons/Audiotrack'

import logo from '../logo/logo_black_192.png'

const MenuItems = [
  {
    key: 'home',
    icon: <HomeIcon />,
    text: 'Übersicht',
    to: '/',
  },
  {
    key: 'news',
    icon: <NewsIcon />,
    text: 'News',
    to: '/news',
  },
  {
    key: 'calendar',
    icon: <CalendarIcon />,
    text: 'Termine',
    to: '/termine',
  },
  {
    key: 'prachings',
    icon: <PreachingsIcon />,
    text: 'Predigten',
    to: '/predigten',
  },
]

const HeaderStyles = makeStyles((theme) => ({
  logo: {
    padding: "20px",
    textAlign: "center",
    "&>img": {
      maxWidth: "100px",
    }
  },
  pushContentBelowAppbar: theme.mixins.toolbar,
  appbar: {
    background: theme.palette.background.dark,
  },
  divider: {
    flexGrow: 1,
  }
}))

const Header = () => {
  const classes = HeaderStyles()
  const location = useLocation()
  const [ showDrawer, setShowDrawer ] = useState(false)
  return (
    <React.Fragment>
      <Drawer anchor='left' open={showDrawer} onClose={() => setShowDrawer(false)}>
        <Box className={classes.logo}>
          <img src={logo} alt="Logo" />
        </Box>
        <List>
          {MenuItems.map((m) => 
            <ListItem button key={m.key} component={Link} to={m.to} onClick={() => setShowDrawer(false)}>
              <ListItemIcon>{m.icon}</ListItemIcon>
              <ListItemText>{m.text}</ListItemText>
            </ListItem>
          )}
        </List>
      </Drawer>
      <Box className={classes.pushContentBelowAppbar} />
      <AppBar position="fixed" className={classes.appbar}>
        <Toolbar>
          <Hidden mdUp>
            <IconButton color='inherit'
              onClick={() => setShowDrawer(true)}>
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden smDown>
            <Tabs value={location.pathname}>
              {MenuItems.map((m) =>
                <Tab icon={m.icon} component={Link} to={m.to} label={m.text} value={m.to} />
              )}
            </Tabs>
          </Hidden>
          <Box className={classes.divider} />
          <ProfileButton />
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}

export default Header
