import React from "react"
import { Box, CircularProgress, Typography, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    margin: 5 + "em",
  },
  spinner: {
    margin: 1 + "em",
  },
  text: {
    fontSize: 1.3 + "em",
  },
}))

const SigningInIndicator = () => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Box className={classes.container}>
        <CircularProgress className={classes.spinner} />
        <Typography className={classes.text}>Du wirst angemeldet ...</Typography>
      </Box>
    </React.Fragment>
  )
}

export default SigningInIndicator
