import { Box, Container, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import useTitle from '../components/layout/UseTitle'
import PlannedEvents from '../components/calendar/PlannedEvents'
import RecurringEvents from '../components/calendar/RecurringEvents'
import Highlight from '../components/common/Highlight'

const CalendarStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    }
  },
  title: {
    textAlign: 'center',
    marginTop: '3rem',
    marginBottom: '2rem',
  },
  recurringEvents: {
    flexBasis: "50%",
    padding: '1em',
  },
  plannedEvents: {
    flexBasis: "50%",
    padding: '1em',
  },
}))

const Calendar = () => {
  useTitle("Termine")
  const classes = CalendarStyles()
  return (
    <React.Fragment>
      <Container maxWidth="lg" className={classes.container}>
        <Box className={classes.recurringEvents}>
          <Typography variant="h2" className={classes.title}>
            <Highlight>Regelmäßige Termine</Highlight>
          </Typography>
          <RecurringEvents />
        </Box>
        <Box className={classes.plannedEvents}>
          <Typography variant="h2" className={classes.title}>
            <Highlight>Geplante Termine</Highlight>
          </Typography>
          <PlannedEvents />
        </Box>
      </Container>
    </React.Fragment>
  )
}

export default Calendar
