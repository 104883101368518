import React from "react"
import { useKeycloak } from '@react-keycloak/web'

const ShowForChurchMember = (props) => {
  const { keycloak, initialized } = useKeycloak();
  const authenticated = initialized && keycloak.authenticated;
  const userIsChurchMember = authenticated && keycloak.hasRealmRole("church_member") && !keycloak.hasRealmRole("new");
  const userIsNew = authenticated && !userIsChurchMember;

  return (
    <React.Fragment>
      {!initialized && (
        props.loadingWidget ? props.loadingWidget : "..."
      )}
      {initialized && !authenticated && (
        // should not happen; the user should be redirected to keycloak in this case
        <span>Du bist nicht angemeldet.</span>
      )}
      {userIsNew && (
        props.userNotActivatedWidget ? props.userNotActivatedWidget : "..."
      )}
      {userIsChurchMember && (
        props.children
      )}
    </React.Fragment>
  )
}

export default ShowForChurchMember
