import React from 'react'
import { useTheme } from '@material-ui/core'

const Highlight = (props) => {
  const theme = useTheme()
  const gradient = 'linear-gradient(0deg, transparent 8%, ' + theme.palette.secondary.main + ' 9%, ' + theme.palette.secondary.light + ' 50%, transparent 51%)'

  return (
    <React.Fragment>
      <span style={{background: gradient}}>{props.children}</span>
    </React.Fragment>
  )
}

export default Highlight
