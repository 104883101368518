import React, { useState } from "react"
import { Button, ListItemIcon, makeStyles, Menu, MenuItem } from "@material-ui/core"
import { useKeycloak } from '@react-keycloak/web'
import UserIcon from '@material-ui/icons/AccountCircle';
import LogoutIcon from '@material-ui/icons/MeetingRoom';

const Styles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.background.dark,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const ProfileButton = () => {
  const classes = Styles()

  const [ anchorEl, setAnchorEl ] = useState(null)
  const { keycloak, initialized } = useKeycloak()

  const authenticated = initialized && keycloak.authenticated;

  const openUserMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closeUserMenu = () => {
    setAnchorEl(null)
  }

  if (authenticated) {
    return (
      <React.Fragment>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          endIcon={<UserIcon />}
          onClick={openUserMenu}
          aria-controls="simple-menu"
          aria-haspopup="true"
          className={classes.button}>
          {keycloak.tokenParsed?.name}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={closeUserMenu}>
          <MenuItem onClick={() => keycloak.logout({redirectUri: "https://liesing.church/"})}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            Abmelden
          </MenuItem>
        </Menu>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
      </React.Fragment>
    )
  }
}

export default ProfileButton
