import React from "react"
import { makeStyles, Container, Typography } from "@material-ui/core"
import { useKeycloak } from '@react-keycloak/web'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 3 + "em",
  },
}))

const UserNotActivatedInformation = () => {
  const classes = useStyles()
  const { keycloak } = useKeycloak()

  return (
    <React.Fragment>
      <Container maxWidth="sm" className={classes.container}>
        <Typography variant="h2">
          Hallo {keycloak.idTokenParsed?.given_name}!
        </Typography>
        <Typography paragraph="true">
          Schön, dass du hier bist! Wie es aussieht, wurde dein Benutzer leider noch nicht aktiviert.
        </Typography>
        <Typography paragraph="true">
          Bitte wende dich an Benjamin (<a href="mailto:benjamin@diehuberts.at">benjamin@diehuberts.at</a>),
          damit dein Benutzer für den internen Bereich freigeschalten wird.
        </Typography>
      </Container>
    </React.Fragment>
  )
}

export default UserNotActivatedInformation
