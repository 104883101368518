import React, { useEffect, useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { useKeycloak } from '@react-keycloak/web'
import config from '../../config/config.json'
import HtmlContent from '../common/HtmlContent'

const Styles = makeStyles((theme) => ({
  content: {
  },
  loadingBox: {
  },
  errorBox: {
  },
}))

const CmsContent = ({contentKey}) => {
  const classes = Styles()
  const { keycloak } = useKeycloak();

  const [ status, setStatus ] = useState("loading")
  const [ content, setContent ] = useState([])

  useEffect(() => {
    const query = "/bff/cms/items/content_internal" +
      "?filter[key][_eq]=" + contentKey +
      "&limit=1"
    fetch(encodeURI(config.bffUrl + query), {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + keycloak.token,
      },
    })
    .then((res) => {
      if (res.ok) return res.json()
      else throw Error(res.statusText)
    })
    .then((res) => {
      if (Array.isArray(res.data) && res.data.length > 0 && res.data[0].content) {
        setContent(res.data[0].content)
        setStatus("done")
      } else {
        setStatus("notfound")
      }
    }, (error) => {
      setStatus("error")
    })
  }, [keycloak, contentKey])

  if (status === "loading") {
    return(
      <Box className={classes.loadingBox}>
        ...
      </Box>
    )
  } else if (status === "error") {
    return(
      <Box className={classes.errorBox}>
        Dieser Inhalt konnte leider nicht geladen werden :-(
      </Box>
    )
  } else if (status === "notfound") {
    return (
      <Box className={classes.errorBox}>
        Dieser Inhalt konnte leider nicht gefunden werden :-(
      </Box>
    )
  } else if (status === "done") {
    return (
      <Box className={classes.content}>
        <HtmlContent html={content} />
      </Box>
    )
  }
}

export default CmsContent

